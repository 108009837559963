/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 30/06/2021
 * Time: 14:24
 */
export const API_CLIENT_ID      = process.env.REACT_APP_API_CLIENT_ID
export const API_CLIENT_SECRET  = process.env.REACT_APP_API_CLIENT_SECRET
export const API_SOCIAL_NETWORK_CLIENT_ID      = process.env.REACT_APP_API_SOCIAL_NETWORK_CLIENT_ID
export const API_SOCIAL_NETWORK_CLIENT_SECRET  = process.env.REACT_APP_API_SOCIAL_NETWORK_CLIENT_SECRET
export const SERVER_BASE_URL    = "https://devevzt.lead-code-group.com/"
export const DEFAULT_AVATAR     = "https://devevzt.lead-code-group.com/img/avatar.jpg"
export const API_BASE_URL                       = SERVER_BASE_URL+"api/"
export const API_DOC_URL                        = API_BASE_URL+"doc.json"
export const API_LOGIN_URL                      = API_BASE_URL+"user/login"
export const API_USER_REGISTER                  = API_BASE_URL+"user/register.json"
export const API_USER_REGISTER_GOOGLE           = API_BASE_URL+"user/google-register.json"
export const API_USER_REGISTER_FB               = API_BASE_URL+"user/fb-register.json"
export const API_USER_RESET_PASSWORD            = API_BASE_URL+"user/reset-password.json"
export const API_USER_GET_INFO_FORGOT_PASSWORD  = API_BASE_URL+"user/get-infos-forgot-password.json"
export const API_USER_RENEW_PASSWORD            = API_BASE_URL+"user/push-new-password.json"
export const API_USER_GET                       = API_BASE_URL+"user/get"
export const API_USER_EDIT                      = API_BASE_URL+"user/edit.json"
export const API_USER_CONTACT                   = API_BASE_URL+"user/contacts.json"
export const API_USER_UPLOAD_PROFILE            = API_BASE_URL+"user/upload"
export const API_USER_EVENTS                    = API_BASE_URL+"event/list.json"
export const API_GUEST_EVENTS                   = API_BASE_URL+"event/guest-list.json"
export const API_EVENT_INFO                     = API_BASE_URL+"event/get/{id}?event={id}"
export const API_EVENT_GUEST                    = API_BASE_URL+"event/get-guests.json?event={id}"
export const API_EVENT_GUEST_ADD                = API_BASE_URL+"event/add-users.json"
export const API_EVENT_ATTACH_USERS             = API_BASE_URL+"event/attach-event-users.json"
export const API_EVENT_DETACH_USERS             = API_BASE_URL+"event/remove-users.json"
export const API_EVENT_CREATE                   = API_BASE_URL+"event/create.json"
export const API_EVENT_EDIT                     = API_BASE_URL+"event/edit.json"
export const API_EVENT_DELETE                   = API_BASE_URL+"event/delete/{id}"
export const API_EVENT_UPLOAD_IMG               = API_BASE_URL+"picture/upload"
export const API_PICTURE_TMP_DELETE             = API_BASE_URL+"picture/delete-tmp/{id}"
export const API_PICTURE_DELETE                 = API_BASE_URL+"picture/delete/{id}"
export const API_PICTURE_RELATED                = API_BASE_URL+"picture/related-random/{limit}"
export const API_EVENT_PICTURES                 = API_BASE_URL+"event/{id}/pictures.json"
export const API_NEWS_LETTER_SUBSCRIPTION       = "news-letter/subscribe.json"
export const API_NEWS_LETTER_UNSUBSCRIPTION     = "news-letter/unsubscribe.json"
export const NBR_CONTACT_PER_PAGE = 4
export const NBR_EVENT_PER_PAGE = 3
export const MB_TO_BYTES        = 1000000;
export const COOKIE_NAME        = "EVZT_APP"
export const INVT_PENDING = -1;
export const INVT_REJECTED = 0;
export const INVT_ACCEPTED = 1;
export const DEFAULT_LANG = "en" ;
export const LANGUAGES = [
    { label: {
         "en" : "English",
         "fr" : "Anglais",
        }, code: "en" },
    { label: {
        "en" : "French",
        "fr" : "Français",
        }, code: "fr" },
];
export const PUBLIC_URI_PATH = [
    "",
    "login",
    "sign-up",
    "forgot-password",
    "renew-password",
    "privacy",
    "about",
    "unsubscribe-newsletter",
    "service",
    "contact",
    "terms",
    "renew-password"
]

export const AUTH_PATH = [
    "login",
    "sign-up",
    "forgot-password",
    "renew-password",
]