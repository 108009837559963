/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 30/06/2021
 * Time: 13:51
 */
import {
    API_CLIENT_ID,
    API_CLIENT_SECRET, API_EVENT_CREATE, API_EVENT_EDIT, API_EVENT_GUEST_ADD,
    API_LOGIN_URL, API_NEWS_LETTER_SUBSCRIPTION, API_SOCIAL_NETWORK_CLIENT_ID, API_SOCIAL_NETWORK_CLIENT_SECRET, API_USER_EDIT,
    API_USER_GET, API_USER_REGISTER, API_USER_REGISTER_FB, API_USER_REGISTER_GOOGLE, API_USER_RENEW_PASSWORD, API_USER_RESET_PASSWORD, SERVER_BASE_URL
} from "../Utils/Defines";
import {getTimestamp, getUserConnected, getUserLang, retrievePersonalInformations, setUserSession} from "../Utils/Common";
import {updateInformation} from "../Store/UserReducer";
import moment from "moment";
const axios = require('axios');
/**
 *
 * @param {Login} loginComponentObj
 * @param dispatch
 * @param {function} majTokenInCookie
 */
export const onSubmitLogin = async (loginComponentObj ,dispatch,majTokenInCookie,majLangueForUser) =>{
        
        await axios.post(API_LOGIN_URL, {
            grant_type: "password",
            client_id: API_CLIENT_ID,
            client_secret: API_CLIENT_SECRET,
            username: loginComponentObj.username.trim(),
            password: loginComponentObj.password.trim()
        })
        .then(function (response) {
            let {data} = response
            if(typeof data.error !== "undefined"){
                switch (data.error) {
                    case "invalid_grant":
                        dispatch.formFeedBackTrans('errors.login.invalid');
                    break;
                    default:
                        dispatch.formFeedBack({err:data.error_description});
                    break;
                }
            } else{
                const access_token = data.access_token
                data.expires_time = getTimestamp()+data.expires_in
                dispatch.setToken(data)
                console.log(majTokenInCookie)
                majTokenInCookie(data)
                axios.defaults.headers.common['Authorization'] = "Bearer "+access_token;
                axios.get(API_USER_GET)
                    .then(function (response) {
                        const {data} = response
                        data.avatar = SERVER_BASE_URL+data.avatar.substring(1)
                        majLangueForUser(data.local)
                        dispatch.onSubmitLogin({
                            isLogged : true,
                            ...data
                        })
                        setUserSession(data)
                    })
                    .catch(function (error) {
                        console.log(error);
                })
            } 
        })
        .catch(function (error) {
            console.log(error);
        })
}
/**
 *
 * @param {Login} loginComponentObj
 * @param dispatch
 * @param {function} majTokenInCookie
 */
export const onSubmitSocialNetWorkUserLogin = async (loginComponentObj ,dispatch,majTokenInCookie,majLangueForUser) =>{
    console.log(process.env.REACT_APP_API_SOCIAL_NETWORK_GRANT_TYPE)
    await axios.post(API_LOGIN_URL, {
        grant_type: process.env.REACT_APP_API_SOCIAL_NETWORK_GRANT_TYPE,
        client_id: API_SOCIAL_NETWORK_CLIENT_ID,
        client_secret: API_SOCIAL_NETWORK_CLIENT_SECRET,
        social_identifier: loginComponentObj.social_identifier.trim(),
        social_type: loginComponentObj.social_type.toLocaleUpperCase()
    })
    .then(function (response) {
        let {data} = response
        if(typeof data.error !== "undefined"){
            //console.log('e:',data.error_description)
            dispatch.formFeedBack({err:data.error_description});
        } else{
            const access_token = data.access_token
            data.expires_time = getTimestamp()+data.expires_in
            dispatch.setToken(data)
            console.log(majTokenInCookie)
            majTokenInCookie(data)
            axios.defaults.headers.common['Authorization'] = "Bearer "+access_token;
            axios.get(API_USER_GET)
                .then(function (response) {
                    const {data} = response
                    data.avatar = SERVER_BASE_URL+data.avatar.substring(1)
                    majLangueForUser(data.local)
                    dispatch.onSubmitLogin({
                        isLogged : true,
                        ...data
                    })
                    setUserSession(data)
                })
                .catch(function (error) {
                    console.log(error);
            })
        } 
    })
    .catch(function (error) {
        console.log(error);
    })
}
/**
 *
 * @param d
 * @param dispatch
 * @param {Function} majTokenInCookie
 */
export const onSubmitInscription = async (d, dispatch, majTokenInCookie) => {
    if (d) {
        let error = false
        if (d.password !== d.confirmPassword) {
            error = {err: "Password not identical"}
        }
        if (!error) {
            d.username = null
            d.local = getUserLang()
            const matchUsername = d.email.match(/(.*)@./)
            if (matchUsername.length > 1) {
                d.username = matchUsername[1]
            }
            if (!d.username) {
                d.username = d.email
            }
            const promiseSignUp = await axios.post(API_USER_REGISTER, d,{
                maxRedirects: 0,
                validateStatus() { return true }
            })
            if(promiseSignUp.status===201){
                const {success} = promiseSignUp.data
                if (success) {
                    dispatch.formFeedBack(false)
                    onSubmitLogin({
                        username: d.username,
                        password: d.password,
                    }, dispatch, majTokenInCookie).then(r => console.log(r))
                }
            }else{
                const dataError = promiseSignUp.data
                const {errors} = dataError
                if (errors) {
                    const messages = []
                    errors.forEach((errorItem) => {
                        for (const errorItemKey in errorItem) {
                            const messageError = errorItem[errorItemKey]
                            console.log(messageError)
                            messages.push(
                                <p key={errorItemKey}>{messageError}</p>
                            );
                        }
                    })
                    error = {err: messages}
                    dispatch.formFeedBack(error)
                }
                console.log(errors)
            }
        } else {
            dispatch.formFeedBack(error)
        }
    }
}

/**
 * @param {string} providerSocial
 * @param d
 * @param dispatch
 * @param {Function} majTokenInCookie
 */
export const onSubmitInscriptionSocialNetwork = async (providerSocial,d, dispatch, majTokenInCookie) => {
    let register_uri = ""
    switch (providerSocial.toLocaleLowerCase()) {
        default:
        case "google":
            d = retrievePersonalInformations({
                family_name:"lastname",
                given_name : "firstname",
                sub:"social_net_work_identify",
                picture:"profile_uri",
                email  :"email"
            },d)      
            register_uri = API_USER_REGISTER_GOOGLE;
        break;
        case "facebook":
            d = retrievePersonalInformations({
                last_name:"lastname",
                first_name :"firstname",
                id:"social_net_work_identify",
                picture:"profile_uri",
                email  :"email",
                gender : "gender"
            },d)  
            register_uri = API_USER_REGISTER_FB;    
        break;
    }
    if (d) {
        let error = false
        if (!error) {
            d.user.local = getUserLang()
            const matchUsername = d.user.email.match(/(.*)@./)
            if (matchUsername.length > 1) {
                d.user.username = matchUsername[1]
            }
            if (!d.user.username) {
                d.user.username = d.user.email
            }
            const promiseSignUp = await axios.post(register_uri, d,{
                maxRedirects: 0,
                validateStatus() { return true }
            })
            if(promiseSignUp.status===201){
                const {success} = promiseSignUp.data
                if (success) {
                    dispatch.formFeedBack(false)
                    onSubmitSocialNetWorkUserLogin({
                        social_identifier: d.social_net_work_identify,
                        social_type: providerSocial,
                    }, dispatch, majTokenInCookie).then(r => console.log(r))
                }
            }else{
                const dataError = promiseSignUp.data
                const {errors} = dataError
                if (errors) {
                    const messages = []
                    errors.forEach((errorItem) => {
                        for (const errorItemKey in errorItem) {
                            const messageError = errorItem[errorItemKey]
                            console.log(messageError)
                            messages.push(
                                <p key={errorItemKey}>{messageError}</p>
                            );
                        }
                    })
                    error = {err: messages}
                    dispatch.formFeedBack(error)
                }
                console.log(errors)
            }
        } else {
            dispatch.formFeedBack(error)
        }
    }
}

/**
 *
 * @param {Login} loginComponentObj
 * @param dispatch
 * @param {function} majTokenInCookie
 */
export const onSubmitFormForgotPassword = async (loginComponentObj ,dispatch,majMessageSuccess) =>{
    let error = false
    await axios.post(API_USER_RESET_PASSWORD, {
        email: loginComponentObj.email.trim(),
        uri  :     window.location.origin+"/renew-password/"
    })
        .then(function (response) {
            let {data} = response
            if(typeof data.error !== "undefined"){
                //console.log('e:',data.error_description)
                dispatch.formFeedBack({err:data.error_description});
            } else{
                majMessageSuccess(data)
            }
        })
        .catch(function (errResp) {
            console.log(errResp.response)
            const dataError = errResp.response.data
            const {errors} = dataError
            if(errors){
                const messages = []
                errors.forEach( (errorItem,pos) => {
                    for (const errorItemKey in errorItem) {
                        const messageError = errorItem[errorItemKey]
                        messages.push(
                            <p key={pos}>{messageError}</p>
                        );
                    }
                })
                error = {err : messages}
                dispatch.formFeedBack(error)
            }
            //Error uri
            if(typeof dataError.error !== "undefined"){
                const messages = []
                if(typeof dataError.error.exception !== "undefined"){
                    dataError.error.exception.forEach( (errorItem,pos) => {
                        const messageError = errorItem.message
                        console.log(messageError)
                        messages.push(
                            <p key={pos}>{messageError}</p>
                        );
                    })
                }else if (typeof dataError.error.message !=="undefined"){
                    messages.push(
                        <p key={0}>{dataError.error.message }</p>
                    );
                }else if (typeof dataError.error =="string"){
                    messages.push(
                        <p key={0}>{dataError.error }</p>
                    );
                }
                error = {err : messages}
                dispatch.formFeedBack(error)
            }
        })
}

/**
 *
 * @param {Login} loginComponentObj
 * @param dispatch
 * @param {function} majTokenInCookie
 */
export const onSubmitFormRenewPassword = async (loginComponentObj ,dispatch,majMessageSuccess,token) =>{
    let error = false
    if (loginComponentObj.password !== loginComponentObj.confirmPassword) {
        error = {err: "Password not identical"}
    }
    if (!error) {
        await axios.post(API_USER_RENEW_PASSWORD, {
            token        : token,
            uri  :     window.location.origin,
            newPassword  :     loginComponentObj.password.trim()
        })
            .then(function (response) {
                let {data} = response
                if(typeof data.error !== "undefined"){
                    //console.log('e:',data.error_description)
                    dispatch.formFeedBack({err:data.error_description});
                } else{
                    majMessageSuccess(data)
                }
            })
            .catch(function (errResp) {
                console.log(errResp.response)
                const dataError = errResp.response.data
                const {errors} = dataError
                if(errors){
                    const messages = []
                    errors.forEach( (errorItem,pos) => {
                        for (const errorItemKey in errorItem) {
                            const messageError = errorItem[errorItemKey]
                            messages.push(
                                <p key={pos}>{messageError}</p>
                            );
                        }
                    })
                    error = {err : messages}
                    dispatch.formFeedBack(error)
                }
                //Error uri
                if(typeof dataError.error !== "undefined"){
                    const messages = []
                    if(typeof dataError.error.exception !== "undefined"){
                        dataError.error.exception.forEach( (errorItem,pos) => {
                            const messageError = errorItem.message
                            console.log(messageError)
                            messages.push(
                                <p key={pos}>{messageError}</p>
                            );
                        })
                    }else if (typeof dataError.error.message !=="undefined"){
                        messages.push(
                            <p key={0}>{dataError.error.message }</p>
                        );
                    }else if (typeof dataError.error =="string"){
                        messages.push(
                            <p key={0}>{dataError.error }</p>
                        );
                    }
                    error = {err : messages}
                    dispatch.formFeedBack(error)
                }
            })
    }else{
        dispatch.formFeedBack(error)
    }

}

export const onSubmitFormEvent = async (d,dispatch) => {
    let event = {};
    const user = getUserConnected();
    if (user) {
        console.log(d.eventDate);
        event = {
            id  : d.id,
            user: {
                "id": user.id
            },
            name: d.name,
            date_event: typeof d.eventDate === "string" ? d.eventDate: d.eventDate.format("YYYY-MM-DD hh:mm:ss"),
            event_configurations: [
                {
                    id:d.configInvtId,
                    type: 1,
                    from: d.emailSender,
                    subject: d.eventSubject,
                    mail_content: d.eventInvitationMessage,
                    sms_content: d.eventInvitationSms,
                },
                {
                    id:d.configShareId,
                    type: 2,
                    from: d.emailShareSender,
                    subject: d.eventShareSubject,
                    mail_content: d.eventShareMessage,
                    sms_content: d.eventShareSms,
                }
            ]
        }


        await axios.post( event.id > 0 ? API_EVENT_EDIT : API_EVENT_CREATE, event)
            .then(function (response) {
                let {data} = response
                console.log('e:',data)
                if (typeof data.error !== "undefined") {
                    console.log('e:',data.error_description)
                   // dispatch.formFeedBack({err: data.error_description});
                } else {
                    if(parseInt(d.id)===0)
                        dispatch.onSuccessEvent(data.success.event);
                    else{
                        dispatch.onUpdatedEvent(data.success.event);
                    }
                    dispatch.setFormEventSaved(true);
                }
            })
            .catch(function (error) {
                const response = error.response.data.error;
                let message = "";
                console.log(response)
                if(typeof response.exception !=="undefined"){
                    console.log(response.exception)
                    response.exception.forEach((item)=>{
                        message += `<p>${item.message}</p>`;
                    })
                }
                dispatch.formFeedBack({err: message});
            }).finally((apiRes) =>{
                console.log(apiRes);
            })
    }
}

export const onSubmitFormGuest = async (d,event,formFeedBack) => {
    const user = getUserConnected();
    if (user) {
        await axios.post( API_EVENT_GUEST_ADD, {
            "users" : [d],
            "client_uri" : window.location.origin,
            "event"      : event.id
        })
            .then( (response) => {
                let {data} = response
                console.log('is ok:',typeof data.success.guests !=="undefined")
                if (typeof data.error !== "undefined") {
                    console.log('e:',data.error_description)
                    formFeedBack({err: data.error_description});
                } else {
                    formFeedBack(data);
                }
            })
            .catch(function (error) {
                let message = "Uknow error";
                if(typeof error !=="undefined" && typeof error.hasOwnProperty("response")){
                    console.log(error)
                    if(typeof error.response !=="undefined" && typeof error.response.hasOwnProperty("data")) {
                        const response = error.response.data.error;
                        console.log(response)
                        if (typeof response.exception !== "undefined") {
                            console.log(response.exception)
                            response.exception.forEach((item) => {
                                message += `<p>${item.message}</p>`;
                            })
                        }
                    }
                }
                formFeedBack({err: message});
            }).finally((apiRes) =>{
                console.log(apiRes);
            })
    }
}

/**
 *
 * @param d
 * @param dispatch
 * @param {Function} setIsSubmit
 * @param {Function} setServerErrors
 * @return {Promise<void>}
 */
export const onSubmitFormEditProfile = async (d,dispatch,setIsSubmit,setServerErrors) => {
    let userToUpdate = {};
    const user = getUserConnected();
    let error = false
    if (d.new_password !== d.confirm_password) {
        error = {err: "Password not identical"}
    }
    if (!error){
        if (user) {
            console.log(d);
            userToUpdate = {
                id  : user.id,
                first_name: d.first_name,
                last_name: d.last_name,
                local: d.local,
                gender:d.gender,
                birth:moment(d.birth).format("YYYY-MM-DDTh:mm:ssP")
            }
            if(d.new_password !==""){
                userToUpdate.new_password = d.new_password.trim()
            }
            if(d.email !== user.email){
                userToUpdate.email = d.email;
            }
            //dispatch(setLoad);
            return  await axios.post( API_USER_EDIT,userToUpdate,{method:"PUT"})
                .then(function (response) {
                    let {data} = response
                    console.log('e:',data)
                    if (typeof data.error !== "undefined") {
                        console.log('e:',data.error_description)
                        // dispatch.formFeedBack({err: data.error_description});
                    } else {
                        data.success.avatar = SERVER_BASE_URL+data.success.avatar.substring(1)
                        data.success.isLogged = true
                        data.success.birth=moment(data.success.birth).format("DD/MM/YYYY")
                        dispatch(updateInformation(data.success))
                    }
                    setIsSubmit(false)
                    return data;
                })
                .catch(function (error) {
                    console.log(error)
                    setIsSubmit(false)
                    if(typeof error.response !=="undefined"){
                        const response = error.response.data.error ?? error.response.data.errors;
                        // let message = "";
                        if(typeof response !=="undefined" && typeof response.exception !=="undefined"){
                            console.log(response.exception)
                            // response.exception.forEach((item)=>{
                            //     message += `<p>${item.message}</p>`;
                            // })
                        }else if(typeof response !=="undefined"){
                            setServerErrors(response)
                        }
                        return error.response;
                    }
                    //dispatch.formFeedBack({err: message});
                }).finally((apiRes) =>{
                    console.log(apiRes);
                    setIsSubmit(false)
                })
        }
    }else{
        setServerErrors(error)
        setIsSubmit(false)
    }
}
/**
 * 
 * @param {FieldValues} d 
 * @param {function} formFeedBack 
 * @returns 
 */
export const onSubmitFormNewsLetter = async (d,formFeedBack) => {
    const user = getUserConnected();
    const data = {...d,client_uri:window.location.origin}
    if (user) {
       
    }
    return await axios.post( API_NEWS_LETTER_SUBSCRIPTION, data)
        .then( (response) => {
            let {data} = response
            console.log('is ok:', data)
            if (typeof data.error !== "undefined") {
                console.log('e:',data.error_description)
                formFeedBack({err: data.error_description});
            } else {
                formFeedBack(data);
            }
        })
        .catch(function (error) {
            let message = "Uknow";
            console.log(error.response.hasOwnProperty("data"))
            if(typeof error.response !=="undefined" && error.response.hasOwnProperty("data")) {
                const response = error.response.data.errors;
                console.log(response)
                if (typeof response ==="object" && response.hasOwnProperty("exception")){
                    console.log(response.exception)
                    response.exception.forEach((item) => {
                        message += `${item.message}`;
                    })
                }else{
                    response.forEach((item) => {
                        for(let k in item){
                            message = `${item[k]}`;
                        }
                    })
                }
            }
            formFeedBack({err: message});
        }).finally((apiRes) =>{
            console.log(apiRes);
        })
}