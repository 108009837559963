/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 22/06/2021
 * Time: 11:46
 */

import Authentication from "./Authentication";
import { Link } from "react-router-dom";
import InputPassword from "../../Utils/InputPassword";
import {
  onSubmitLogin,
  onSubmitSocialNetWorkUserLogin,
} from "../../EventHandler/OnSubmitEvent";
import { connect } from "react-redux";
import {
  addToken,
  updateInformation,
  setMessageErr,
  cleanMessageErr,
} from "../../Store/UserReducer";
import { useForm } from "react-hook-form";
import ErrorMessageEvztField from "../../Blocks/ErrorMessageEvztField";
import SpinerLoading from "../../Blocks/SpinerLoading";
import ErrorFeedBack from "../../Blocks/ErrorFeedBack";
import { useState } from "react";
import { withCookies } from "react-cookie";
import TransparentLogo from "../../Blocks/TransparentLogo";
import { useTranslation } from "react-i18next";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import {
  GoogleSingUpButton,
  FacebookSingUpButton,
} from "../../Pages/Authentication/button/SingInButton";
import { useCallback } from "react";
import { LinkToCreateNewAccount } from "../../Blocks/LinkToCreateNewAccount";
import { t } from "i18next";

const Login = (prop) => {
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm();
  const feedBackErr = prop.user.err;
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const { cookies } = prop;
  const majTokenInCookie = (data) => {
    cookies.set("ac", JSON.stringify(data), { path: "/", maxAge: 172800 });
  };
  const { t, i18n } = useTranslation();
  const majLangueForUser = (local) => {
    if (i18n.language !== local) {
      i18n.changeLanguage(local).then((r) => {});
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLoginStart = useCallback(() => {
    console.log("Login start");
  });

  return (
    <>
      <div className="right-side">
        <p>
          {t("dontHaveAccountText")}?
          <Link to={"/sign-up"}>{t("createAccount")}</Link>
        </p>
      </div>
      <div className="content-auth">
        <ErrorFeedBack
          message={feedBackErr}
          classList={"evzt-alert error"}
          callBack={prop.cleanMessageErr}
        />

        <div className={"content-auth-form"}>
          <Link to={"/"}>
            <TransparentLogo />
          </Link>
          <form
            id={"formSignIn"}
            onSubmit={handleSubmit((d) =>
              onSubmitLogin(d, prop, majTokenInCookie, majLangueForUser)
            )}
          >
            <div className="auth-social-login">
              <p className="evzt-text-center">{t("socialSigninText")}</p>
              <div className="evzt-social-btn-container">
                <div className="auth-social-form-group">
                  <LoginSocialGoogle
                    isOnlyGetToken={false}
                    client_id={process.env.REACT_APP_GOOGLE_APP_ID}
                    onLoginStart={onLoginStart}
                    onResolve={({ provider, data }) =>
                      onSubmitSocialNetWorkUserLogin(
                        {
                          social_identifier: data.sub,
                          social_type: provider,
                        },
                        prop,
                        majTokenInCookie
                      )
                    }
                    onReject={(err) => {
                      console.log(err);
                    }}
                    scope={"profile email"}
                  >
                    <GoogleSingUpButton />
                  </LoginSocialGoogle>
                </div>
                <div className="auth-social-form-group">
                  <LoginSocialFacebook
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    fieldsProfile={
                      "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
                    }
                    onLoginStart={onLoginStart}
                    onResolve={({ provider, data }) =>
                      onSubmitSocialNetWorkUserLogin(
                        {
                          social_identifier: data.id,
                          social_type: provider,
                        },
                        prop,
                        majTokenInCookie
                      )
                    }
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <FacebookSingUpButton />
                  </LoginSocialFacebook>
                </div>
              </div>
            </div>
            <div className="auth-input-form-group">
              <input
                type="text"
                {...register("username", { required: t("requiredField") })}
                placeholder={t("username")}
              />
              <ErrorMessageEvztField errors={errors} fieldName={"username"} />
            </div>

            <div className="auth-input-form-group">
              <InputPassword
                inputName="password"
                register={register}
                validationRules={{ required: t("requiredField") }}
                errors={errors}
                placeHoldIt={t("password")}
                isVisiblePassword={isVisiblePassword}
                setIsVisiblePassword={setIsVisiblePassword}
              />
            </div>
            {/* className="auth-input-form-group customized-checkbox-block" */}
            <div className="auth-input-form-grou customized-checkbox-block">
              <input
                type="checkbox"
                {...register("rememberMe")}
                errors={errors}
              />
              <label className="custom-label">{t("rememberMe")}</label>
              <ErrorMessageEvztField errors={errors} fieldName={"rememberMe"} />
            </div>
            <button
              disabled={isSubmitting}
              type={"submit"}
              className="btn-submit submit-load"
            >
              {t("signIn")}
              {isSubmitting && <SpinerLoading />}
            </button>

            <div className="auth-form-footer evzt-mb-1">
              <Link className="" to={"/forgot-password"}>
                {t("forgotPassword")}
              </Link>
            </div>
          </form>
        </div>
      </div>
      <div className="left-side">
        <LinkToCreateNewAccount />
      </div>
    </>
  );
};

export const LoginStore = connect(
  (state) => ({
    user: state.user,
    err: state.err,
  }),
  (dispatch) => ({
    formFeedBackTrans: (text) => dispatch(setMessageErr({ err: t(text) })),
    onSubmitLogin: (user) => dispatch(updateInformation(user)),
    setToken: (token) => dispatch(addToken(token)),
    formFeedBack: (err) => dispatch(setMessageErr(err)),
    cleanMessageErr: (err) => dispatch(cleanMessageErr(err)),
  })
)(Login);

export default Authentication(withCookies(LoginStore));
