import { useParams } from "react-router-dom/cjs/react-router-dom";
import Header from "../../Blocks/Header";
import ProfileBlock from "../../Blocks/ProfileBlock";
import ContactsListBlock from "../../Blocks/ContactsListBlock";
import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { getEventById, getEventsImages } from "../../Utils/Common";
import { API_PICTURE_DELETE, SERVER_BASE_URL } from "../../Utils/Defines";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import { Gallery as GalleryLibrary } from "react-grid-gallery";
import Counter from "yet-another-react-lightbox/plugins/counter";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LoadingItemsHorizontal from "../../Blocks/LoadingItemsHorizontal";
import RenderSlideCustom from "../../Blocks/LightBoxCustom/RenderSlideCustom";
import Download from "yet-another-react-lightbox/plugins/download";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ModalConfirmRemoveEvent from "../../Blocks/Modal/ModalConfirmRemoveEvent";
import axios from "axios";
import PrivateFooter from "../../Blocks/PrivateFooter";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import ModalUploadImageEvent from "../../Blocks/Modal/ModalUploadImageEvent";
import { Icon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { BrowserView, MobileView } from "react-device-detect";
import PrivateMobileFooter from "../../Blocks/Mobiles/PrivateMobileFooter";
import SocialShare from "./SocialShare";
/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 23/03/2023
 * Time: 16:51
 */
const Gallery = () => {
  const theme = useTheme();
  const { idEvent } = useParams();
  const [event, setEvent] = useState(null);
  const [eventLoading, setEventLoading] = useState(false);
  const [openModalUpload, setOpenModalUpload] = React.useState(false);
  const [images, setImages] = useState(false);
  const [index, setIndex] = useState(-1);
  const [pagination, setPagination] = useState({
    next: 1,
    prev: 0,
    perPage: 0,
    currentItems: 0,
    count: 0,
  });
  const [loadImages, setLoadImages] = useState(false);
  const refPrincipal = useRef(null);
  const [open, setOpen] = React.useState(false);
  const currentImage = images[index];
  const [openModalRemove, setOpenModalRemove] = React.useState(false);
  const [hasSelected, setHasSelected] = useState(false);
  const [countSelected, setCountSelected] = useState(0);
  const [openModalDialogMultipleRemove, setOpenModalDialogMultipleRemove] =
    useState(false);

  /** Fuctions */
  const { t } = useTranslation();

  const handleClick = (index, item) => {
    setIndex(index);
    setOpen(true);
    console.log(item);
  };
  const handleYes = async ({ id }) => {
    const res = await onRemovePicture(id);
    if ("PICTURE_DELETED" === res) {
      setOpen(false);
      setOpenModalRemove(false);
    }
  };
  const handleNo = () => {
    setOpenModalRemove(false);
  };
  const fetchImage = () => {
    const paramsGet = {
      ...pagination,
      filters: [],
    };
    setLoadImages(true);
    getEventsImages(idEvent, paramsGet)
      .then((data) => {
        if (data) {
          const realData = data.items.map((item, p) => {
            const tags = [];
            item.src = SERVER_BASE_URL + item.src;
            item.downloadUrl = `${item.origin}?download`;
            item.downloadFilename = `${event.name}-${p + 1}`;
            //item.download=`${item.origin}?download`
            item.title = "";
            if (typeof item.tags !== "undefined")
              item.tags.forEach((tag) => tags.push(tag.title));
            item.description = tags.join(", ");
            item.tags = [];
            return item;
          });
          setImages((prevImages) =>
            prevImages ? [...prevImages, ...realData] : realData
          );
          setPagination((prevPagination) => {
            prevPagination.prev =
              data.pagination.prev === "" ? 0 : data.pagination.prev;
            prevPagination.next =
              data.pagination.next === "" ? 0 : data.pagination.next;
            prevPagination.count = data.count;
            prevPagination.currentItems += data.items.length;
            return prevPagination;
          });
        } else {
          setImages([]);
        }
        setLoadImages(false);
      })
      .finally(() => setLoadImages(false));
  };
  /**
   * @todo make ajax api delete and don't forget alert confirm
   * @param ev
   */
  const handleDelete = (ev) => {
    setOpenModalRemove(true);
  };
  const delay = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };
  const handleCloseModalUpload = () => {
    setOpenModalUpload(false);
  };

  /**
   *
   * @param  id {number}
   * @returns {Promise<*>}
   */
  const onRemovePicture = async (id) => {
    try {
      let response = await axios.get(API_PICTURE_DELETE.replace("{id}", id));
      const { data } = response;
      await delay(300);
      setPagination((prevPagination) => {
        prevPagination.count -= 1;
        return prevPagination;
      });
      setImages((prevImages) =>
        prevImages.filter((image, p) => image.id !== id)
      );
      return data.success;
    } catch (error) {
      return error.response;
    }
  };
  if (!event && !eventLoading) {
    setEventLoading(true);
    getEventById(idEvent)
      .then((res) => {
        if (res.success) {
          setEvent(res.success.event);
        }
      })
      .catch(() => null)
      .finally(() => setEventLoading(false));
  }
  if (!loadImages && !images && event) {
    fetchImage();
  }

  useLayoutEffect(() => {
    //setHeightBlockPrincipal(refPrincipal.current.offsetHeight);
    setPagination((prevPagination) => {
      const sizeBase =
        refPrincipal.current.offsetHeight > 0
          ? refPrincipal.current.offsetHeight
          : refPrincipal.current.offsetWidth;
      prevPagination.perPage = Math.floor(4 * (sizeBase / 180));
      // console.log(refPrincipal.current,sizeBase,prevPagination)
      return prevPagination;
    });
  }, []);

  /**
   *
   * @returns {number|number}
   */
  const getCurrentNbLoader = () => {
    return pagination.count === 0 ||
      pagination.count - pagination.currentItems > pagination.perPage
      ? pagination.perPage
      : pagination.count - pagination.currentItems;
  };

  if (currentImage) {
    currentImage.name = t("picture");
  }
  const handleSelectedImages = useCallback(() => {
    if (images) {
      let selected = false;
      images.forEach((objImage) => {
        if (objImage.isSelected) {
          selected = true;
          return;
        }
      });
      setHasSelected(selected);
      setCountSelected(images.filter((img) => true === img.isSelected).length);
    }
  }, [images]);

  // La fonction handleSelectedImages sera appelée chaque fois que images sont chargées ou mises à jour
  useEffect(() => {
    handleSelectedImages();
  }, [images, handleSelectedImages]);

  const handleSelect = (index) => {
    const updatedImageList = images.map((image, i) =>
      i === index ? { ...image, isSelected: !image.isSelected } : image
    );
    setImages(updatedImageList);
  };

  const deleteAllSelectedImages = async () => {
    const imagesToRemove = images.filter((img) => true === img.isSelected);
    const removeCount = imagesToRemove.length;
    if (removeCount > 0) {
      let removedCounter = 0;
      images.forEach(async (img, p) => {
        if (true === img.isSelected) {
          const res = await onRemovePicture(img.id);
          if ("PICTURE_DELETED" === res) {
            removedCounter++;
          }
          if (removedCounter === removeCount) {
            setOpenModalDialogMultipleRemove(false);
          }
        }
      });
    }
  };

  const haveAnImageSelected = () => {
    return images.filter((img) => true === img.isSelected).length > 0;
  };

  const handleSelectAll = () => {
    const nextImages = images.map((image) => ({
      ...image,
      isSelected: !hasSelected,
    }));
    setImages(nextImages);
  };

  return (
    <>
      <Header />
      <div className="evzt-container app-content">
        <BrowserView>
          <div className={"app-left-side"}>
            <ProfileBlock />
            <ContactsListBlock />
          </div>
        </BrowserView>
        <div className="principal-content" ref={refPrincipal}>
          <div className={"event-block"}>
            <div className="evzt-row">
              <div className="evzt-lg-8">
                {event && (
                  <h2>
                    {t("picturesFor")} <strong>#{event.name}</strong>
                  </h2>
                )}
                {pagination.count > 0 && (
                  <p className={"evzt-title-desc"}>
                    {t("pagination.gallery.description", {
                      count: pagination.count,
                      items: images.length,
                    })}
                  </p>
                )}
              </div>
              {/* @todo : Access from owner  */}
              {event && event.folders && (
                <div className="import-Photos-gallery">
                  <Link
                    to={"#"}
                    className="evzt-btn btn-white"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setOpenModalUpload(true);
                    }}
                  >
                    {t("form.upload.btn")}
                  </Link>
                </div>
              )}
            </div>
            <div className="gallery-actiongroup">
              <div className="actiongroup-gallery-button">
                <button
                  className="evzt-btn btn-icon"
                  onClick={handleSelectAll}
                  style={{ cursor: "pointer" }}
                >
                  {hasSelected ? (
                    <Icon color="primary">check_box</Icon>
                  ) : (
                    <Icon color="primary">check_box_outline_blank_icon</Icon>
                  )}
                </button>
                {hasSelected && (
                  <>
                    <button
                      className="evzt-btn btn-icon"
                      onClick={() => setOpenModalDialogMultipleRemove(true)}
                      style={{ cursor: "pointer" }}
                      disabled={hasSelected ? false : true}
                    >
                      <Icon style={{ color: theme.status.danger }}>
                        delete_forever
                      </Icon>
                    </button>
                    <button className="evzt-btn btn-icon">
                      <Icon color="secondary">share</Icon>
                    </button>
                  </>
                )}
              </div>
              <div className="BackToMyspace">
                <Link to={"/my-space"} title="back to Myspace">
                  <button className="evzt-btn btn-white">
                    {" "}
                    <Icon>arrow_back</Icon> {t("BackToMyspace")}
                  </button>
                </Link>
              </div>
            </div>
            <div className="random-images-gallery">
              {images.length > 0 && (
                <GalleryLibrary
                  onClick={handleClick}
                  enableImageSelection={true}
                  onSelect={handleSelect}
                  images={images}
                />
              )}

              {!!currentImage && (
                <Lightbox
                  open={open}
                  close={() => setOpen(false)}
                  slides={images}
                  index={index}
                  plugins={[Download, Counter, Captions]}
                  toolbar={{
                    buttons: [
                      currentImage.id > 0 && (
                        <IconButton
                          key={"rm-picture"}
                          fontSize="inherit"
                          aria-label="delete"
                          className="yarl__button"
                          size="large"
                          onClick={handleDelete}
                        >
                          <DeleteIcon fontSize="inherit" color="error" />
                        </IconButton>
                      ),
                      <IconButton
                        key={"share-facebook"}
                        fontSize="inherit"
                        aria-label="share-facebook"
                        className="yarl__button"
                        size="large"
                      >
                        <SocialShare
                          provider="facebook"
                          url={currentImage.origin}
                        />
                      </IconButton>,
                      <IconButton
                        key={"share-messenger"}
                        fontSize="inherit"
                        aria-label="share-messenger"
                        className="yarl__button"
                        size="large"
                      >
                        <SocialShare
                          provider="messenger"
                          url={currentImage.origin}
                        />
                      </IconButton>,
                      <IconButton
                        key={"share-whatsapp"}
                        fontSize="inherit"
                        aria-label="share-whatsapp"
                        className="yarl__button"
                        size="large"
                      >
                        <SocialShare
                          provider="whatsapp"
                          url={currentImage.origin}
                        />
                      </IconButton>,
                      "download",
                      "close",
                    ],
                  }}
                  render={{
                    slide: ({ slide }) => <RenderSlideCustom slide={slide} />,
                  }}
                  carousel={{
                    padding: 0,
                    spacing: 0,
                    image: "cover",
                  }}
                  animation={{
                    fade: 250,
                    swipe: 100,
                    easing: {
                      fade: "ease",
                      swipe: "ease-out",
                      navigation: "ease-in-out",
                    },
                  }}
                  counter={{
                    container: { style: { top: 0, bottom: "unset" } },
                  }}
                />
              )}
            </div>
            {loadImages && (
              <LoadingItemsHorizontal count={getCurrentNbLoader()} />
            )}
            <div className="loader-data-block">
              {images.length > pagination.perPage &&
                parseInt(pagination.prev) > 0 && (
                  <Link
                    className="evzt-btn-load less"
                    to={"#"}
                    onClick={(ev) => {
                      const maxList = images.length - pagination.perPage;
                      setPagination((prevPagination) => {
                        console.log(prevPagination);
                        prevPagination.next = prevPagination.prev + 1;
                        prevPagination.prev =
                          prevPagination.prev - 1 > 0
                            ? prevPagination.prev - 1
                            : 0;
                        prevPagination.currentItems = maxList;

                        return prevPagination;
                      });
                      setImages((prevImages) => prevImages.slice(0, maxList));
                    }}
                  >
                    {t("loadLess")} <Icon>remove_circle</Icon>{" "}
                  </Link>
                )}
              {pagination.count > pagination.perPage && pagination.next > 0 && (
                <Link
                  className="evzt-btn-load more"
                  to={"#"}
                  onClick={(ev) => fetchImage()}
                >
                  {t("loadMore")} <Icon>add_circle</Icon>
                </Link>
              )}
            </div>
          </div>
        </div>
        <BrowserView>
          <PrivateFooter />
        </BrowserView>
        <MobileView>
          <PrivateMobileFooter />
        </MobileView>
        {currentImage && (
          <ModalConfirmRemoveEvent
            open={openModalRemove}
            objectToRemove={currentImage}
            handleNo={handleNo}
            handleYes={handleYes}
          />
        )}
        {event && (
          <ModalUploadImageEvent
            open={openModalUpload}
            handleClose={handleCloseModalUpload}
            event={event}
          />
        )}
        {haveAnImageSelected && (
          <ModalConfirmRemoveEvent
            open={openModalDialogMultipleRemove}
            objectToRemove={{ name: "Selected Picture", count: countSelected }}
            handleNo={() => setOpenModalDialogMultipleRemove(false)}
            handleYes={deleteAllSelectedImages}
          />
        )}
      </div>
    </>
  );
};

export default Gallery;
