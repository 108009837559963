/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 15/04/2023
 * Time: 11:49
 */
import {MenuLang} from "./MenuLang";
import {useTranslation} from "react-i18next";
// import {Link} from "@mui/material";
import FormNewsLetter from "./Form/FormNewsLetter"
import { Link } from "react-router-dom";

const PublicFooter = () => {
    const d = new Date()
    const {t} = useTranslation()
   return (
       <section id={"pub-footer"} className="pub-footer ">
        <div className="evzt-container">
            <div className="evzt-language-menu">
                <MenuLang/>
            </div>
            <div className="evzt-bottom-newsletter">
                <FormNewsLetter />
            </div>
            <div className="links">
                <ul className="link-list">
                    <li><Link to={"/"} color="inherit">{t("home")}</Link></li>
                    <li><Link to={"/about"} color="inherit">{t("about")}</Link></li>
                    <li><Link to={"/terms/services"} color="inherit">{t("termsOfService")}</Link></li>
                    <li><Link to={"/privacy/policy"} color="inherit">{t("privacy")}</Link></li>
                    <li><Link to={"/login"} color="inherit">{t("signIn")}</Link></li>
                </ul>
            </div>
            <div className={"evzt-copy-right evzt-text-center"}>
                <strong>
                    {d.getFullYear()}&nbsp;<span className={"evzt-text-primary"}>&copy;</span> Lead Code Group
                </strong>
            </div>
        </div>
       </section>
   )
}

export default PublicFooter;