import {connect} from "react-redux";
import {addToken, cleanMessageErr, setMessageErr, updateInformation} from "../../Store/UserReducer";
import Authentication from "./Authentication";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import ErrorFeedBack from "../../Blocks/ErrorFeedBack";
import {onSubmitFormForgotPassword} from "../../EventHandler/OnSubmitEvent";
import ErrorMessageEvztField from "../../Blocks/ErrorMessageEvztField";
import SpinerLoading from "../../Blocks/SpinerLoading";
import { useState} from "react";
import {Alert} from "@mui/material";
import TransparentLogo from "../../Blocks/TransparentLogo";
import { LinkToCreateNewAccount } from "../../Blocks/LinkToCreateNewAccount";
import { t } from "i18next";
/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 09/03/2023
 * Time: 09:48
 */

const ForgotPassword = (prop) => {
    const {register, formState:{errors, isSubmitting}, handleSubmit} = useForm();
    const feedBackErr = prop.user.err;
    const [successMessage,setSuccessMessage] = useState(false);
    const majMessageSuccess = (data) => {
        const {success} = data
        console.log(success)
        if(success==="SUCCESS"){
            prop.cleanMessageErr()
            setSuccessMessage(t("messages.sucess.requestedRenewPassWord"));
        } else
            setSuccessMessage(false)
    }
    return (
        <>
            
            <div className="right-side">
                <p></p>
            </div>
            <div className="content-auth">
                <ErrorFeedBack message={feedBackErr} classList={"evzt-alert error"} callBack={prop.cleanMessageErr}/>
                {successMessage && <Alert severity="success">{successMessage}</Alert>}
                <div className={'content-auth-form'}>
                    <TransparentLogo />
                    <form id={"formForgotPassword"} onSubmit={handleSubmit((d)=>onSubmitFormForgotPassword(d,prop,majMessageSuccess))}>
                        <div className="auth-input-form-group">
                            <input type="email" {...register("email", {required: 'Email is required'})} placeholder={t("email")}/>
                            <ErrorMessageEvztField errors={errors} fieldName={'email'}/>
                        </div>
                        <button disabled={isSubmitting} type={"submit"} className="btn-submit submit-load">{t('form.forgotPassword.send')}{isSubmitting && <SpinerLoading/>}
                        </button>
                        <div className="auth-form-footer">
                            <Link to={"/login"}>{t('form.forgotPassword.backToLogin')}</Link>
                        </div>
                    </form>
                </div>
            </div>
            <div className="left-side">
                <LinkToCreateNewAccount />
            </div>
        </>
    );
}

const ForgotPasswordStore = connect(
    (state) =>({
        user : state.user,
        err  : state.err
    }) ,
    (dispatch) => ({
        onSubmitLogin : user  => dispatch(updateInformation(user)),
        setToken      : token => dispatch(addToken(token)),
        formFeedBack  : err => dispatch(setMessageErr(err)),
        cleanMessageErr  : err => dispatch(cleanMessageErr(err))
    })
)(ForgotPassword)

export default Authentication(ForgotPasswordStore)