import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getUserLang } from "./Common";

i18n.use(initReactI18next).init({
    lng: getUserLang(),
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                share:{
                    FB : "Share this photo in Facebook"
                }
            }
        },
        fr: {
            translation: {
                share:{
                    FB : "Partager cette photo dans Facebook"
                }
            }
        }
    }
})