/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 07/04/2023
 * Time: 21:16
 */

import React, { useEffect } from 'react';
import { useTranslation, Trans } from "react-i18next";
import PublicFooter from "../Blocks/PublicFooter";
import PublicHeader from "../Blocks/PublicHeader";
import firstImage from "../assets/image/beautiful-bride-with-her-husband-park.jpg";
import secondImage from "../assets/image/elderly-people-celebrating-their-birthday.jpg";
import thirdImage from "../assets/image/people-having-fun-block-party.jpg";

const Home = () => {
    const { t } = useTranslation();
    useEffect(() => {
        document.body.classList.add('evzt-home');
        return () => {
            document.body.classList.remove('evzt-home');
        };
    }, []);
    return (
        <>
            <PublicHeader />
            <section id={"homeBlock"} className="home-block">
                <div className="evzt-container">
                    <div className="evzt-about evzt-row">
                        <div className="evzt-about-desc">
                            <h1>{t("aboutEvzt")}</h1>
                            <div className="evzt-about-image" id="h1-sous-image"><img src="https://picsum.photos/id/250/765/350" alt="" /></div>
                            <p>{t("whatIsEvzt")}</p>
                            <Trans i18nKey="characteristics"></Trans>
                        </div>
                        <div className="evzt-about-image" id="sous-image"><img src="https://picsum.photos/id/250/765/350" alt="" /></div>
                    </div>
                </div>
            </section>
            <section id="homeBanner" className="home-banner">
                <div className="evzt-container">
                    <h2>Lorem ipsum dolor sit, amet consectetur adipisicing elit ?</h2>
                    <p>Voluptatibus laudantium sapiente ipsa corporis? Dolore quaerat, qui eum soluta eaque sit adipisci dolorem tempore voluptas, architecto alias distinctio! Veritatis, delectus animi.</p>
                </div>
            </section>
            <section id="homeLatestEvents" className="home-latest-events">
                <div className="evzt-container evzt-text-center">
                    <h2>{t("OurMostRecentEvent")}</h2>
                    <p>Dolore quaerat, qui eum soluta eaque sit adipisci dolorem tempore voluptas, architecto alias distinctio.</p>
                </div>
                <div className="evzt-home-lastest-events-images">
                    <div className="image-home-lastest-events"><img src={firstImage} alt="" /></div>
                    <div className="image-home-lastest-events"><img src={secondImage} alt="" /></div>
                    <div className="image-home-lastest-events"><img src={thirdImage} alt="" /></div>
                </div>
            </section >
            <PublicFooter />
        </>
    );
}

export default Home;