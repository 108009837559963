import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import EvtRouter from './EvztRouter';
import Store from './Store/Store'
import { Provider } from 'react-redux'
//styles
import "./assets/scss/global.scss";
import "./assets/scss/partials/_header.scss"
import {CookiesProvider} from "react-cookie";
import "./Utils/i18n"
import "./Utils/Buttonsi18n"
import EvztTheme from './evzt-theme';
import { ThemeProvider } from '@mui/material/styles';

ReactDOM.render(
    <Provider store={Store}>
      <ThemeProvider theme={EvztTheme}>
        <Router>
          <CookiesProvider>
            <EvtRouter />
          </CookiesProvider>
        </Router>
      </ThemeProvider>
    </Provider>,
  document.getElementById('evzt')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
