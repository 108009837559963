import {Link, useLocation} from "react-router-dom";
import {SERVER_BASE_URL} from "../Utils/Defines";
import { useTranslation } from "react-i18next";
import { useState } from "react";


const PublicHeader = () =>{

    const location = useLocation();
    const {t}      = useTranslation()

    const [style, setStyle] = useState('none');
    const toggleStyle = () => {
        if (style === 'none') {
        setStyle('block');
        } else {
        setStyle('none');
        }
    };

    return (
        <header className={"header"}>
            <div className="header-container evzt-container" id="first">
                <Link to={"/"} className="header-brand">
                    <img src={SERVER_BASE_URL+"img/logo/light.png"} alt="logo" />
                </Link>
                <ul class="menu-list">
                    <li className={location.pathname === '/' && 'active'}>
                        <Link to={"/"}>{t("home")}</Link>
                    </li>
                    <li className={location.pathname === '/service' && 'active'}>
                        <Link to={"/service"}>Service</Link>
                    </li>
                    <li className={location.pathname === '/contact' && 'active'}>
                        <Link to={"/contact"}>Contact</Link>
                    </li>
                    <li className={location.pathname === '/about' && 'active'}>
                        <Link to={"/about"}>{t("about")}</Link>
                    </li>
                    <li><Link to={"/login"} color="inherit">{t("signIn")}</Link></li>
                </ul>
                <button onClick={toggleStyle}  id="open-menu" type="submit"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M0 0h24v24H0z" stroke="none"/><path d="M4 6h16M4 12h16M4 18h16"/></svg></button>
            </div>
            <div class="header-container-res" id="def" style={{display: `${style}`}} >
                <div className='header-container-res' id='evzt-menu'>
                    <div id="evzt-logo">
                        <div id='image-logo'>
                                <Link to={"/"} className="header-brand">
                                    <img src={SERVER_BASE_URL+"img/logo/light.png"} alt="logo" />
                                </Link>
                        </div>
                        <div id='closedme'>
                                <button onClick={toggleStyle} id="closedmenue" type="submit">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M0 0h24v24H0z" stroke="none"/><path d="M18 6 6 18M6 6l12 12"/></svg>
                                </button>
                        </div>
                    </div>
                    <div id='nav-menu'>
                        <ul className="header-res">
                            <li>
                                <Link to={"/"}>{t('home')}</Link>
                            </li>
                            <li>
                                <Link to ={"/service"}>Service</Link>
                            </li>
                            <li>
                                <Link to={"/contact"}>Contact</Link>
                            </li>
                            <li>
                                <Link to={"/about"}>{t("about")}</Link>
                            </li>  
                            <li>
                                <Link to={"/login"}>{t("signIn")}</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default PublicHeader;