/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 08/04/2023
 * Time: 12:01
 */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getUserLang } from "./Common";

i18n.use(initReactI18next).init({
  lng: getUserLang(),
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        alreadyAnAccount: "Already have an account",
        dontHaveAccountText: "Don't have an account feed-back",
        createAccount: "Create an account",
        signUp: "Sign Up",
        signIn: "Sign in",
        home: "Home",
        ourService: "Our services",
        ServiceItems: {
          Wedding: "Wedding",
          Birthday: "Birthday",
          RestaurationDecoration: "Restoration & Decoration",
          Findetude: "Graduation ceremony",
          ConferenceEvent: "Conference event",
          CharityEvent: "Charity event",
          CorporateEvent: "Corporate Event",
          EventPhotography: "Event Photography",
          Exposition: "Exhibition",
          FamilyHoliday: "Family garthering",
        },
        BackToMyspace: "Back to Myspace",
        AboutUs: "About Us",
        MorePosts: "MORE POSTS",
        SendRequest: "SEND REQUEST",
        UsersReviews: "Users Reviews",
        ArticlesfromtheBlog: "Articles from the Blog",
        ContactUs: {
          Title: "Contact us",
          Adress: "Location",
          Phone: "phone",
          ContactForum: "CONTACT FORM",
          ContactUS: "Contact us",
          FirstName: "First name",
          LastName: "Last name",
          YourMessage: "Send us a message",
          CommentOrMessage: "Comment/Message",
          Send: "SEND",
        },
        OurMostRecentEvent: "Our most recent events",
        logOut: "Logout",
        firstName: "First name",
        lastName: "Last name",
        password: "Password",
        newPassword: "New password",
        passwordConfirm: "Confirm Password",
        email: "Email",
        username: "Username",
        rememberMe: "Keep me signed in",
        forgotPassword: "Forgotten password ",
        requiredField: "This field is required",
        ifDontHaveAccount: "If you don't have an account",
        itSQuickEasy: "It is quick and easy",
        language: "Language",
        btnUpdateProfil: "Confirm and save",
        formMsgSuccessUpdProfile: "Success updated profile",
        myEvents: "My events",
        contacts_one: "Contact",
        contacts_more: "Contacts",
        noEvent: "Nothing event for you on time",
        eventsImInvited: "Events who i am invited",
        noEventInvited: "You are not invited on nothing event at the time",
        relatedImages: "Related images",
        about: "About",
        aboutEvzt: "Understanding Event Zara Tof: Our Vision and Mission",
        privacy: "Privacy Policy",
        termsOfService: "Terms of Service",
        langChoice: "Language",
        whatIsEvzt:
          "Event-Zaratof is an online platform for people that organize often events which need a space and above all sharing the pictures by face recognition technology.",
        characteristics:
          "<p>Event-ZaraTof helps you plan your event , such as managing guests for each event ,  also in event you can personalize your Event Title , date time ,invitation content email and picture link sharing mail to send with your guests.</p>\n" +
          "\n" +
          "<p>Thanks to facial recognition, the person attending the event automatically received the link of the photo on which they were.</p> \n" +
          "<p><strong>So how do you make this happen ?</strong></p>\n" +
          "\n" +
          "<p>For this, a guest has received an invitation by email or sms must go to Event-ZaraTof and download their correct profile avatar.</p>\n",
        picturesFor: "Pictures for event",
        events_one: "Event",
        events_more: "Events",
        loadMore: "Load more",
        loadLess: "Load less",
        manageEvents: "Manage events",
        newEvent: "Create new event",
        aboutYou: "About you",
        security: "Security",
        formEventName: "Event name",
        formEventNameDesc: "Put a name of your event here",
        formEventDate: "Event's Date",
        formEventConfigurationInvt: "Configuration invitation",
        formEventEmail: "Sender email",
        formEventEmailDesc: "Put the sender email",
        formEventSubject: "Subject of mail",
        formEventSubjectDesc: "Put the Subject of mail",
        formEventMailInvtContent: "Mail invitation content",
        formEventSMSInvtContent: "SMS invitation content",
        formEventConfigurationShare: "Share Picture's configuration",
        formEventShareEmail: "Sender email",
        formEventEmailShareDesc: "Put the sender email",
        formEventShareSubject: "Subject share mail",
        formEventShareSubjectDesc: "Put the Subject of mail",
        formEventShareMailInvtContent: "Mail share content",
        formEventShareMailShareContent: "Mail share content",
        formEventShareSMSInvtContent: "SMS share content",
        formEventShareSMSContentDesc: "SMS share content",
        formBtnCreate: "Create",
        formUserFirstname: "First Name",
        formUserLastname: "Last Name",
        formUserUsername: "Username",
        formUserEmail: "Email",
        formUserGender: "Gender",
        formUserGenderMale: "Male",
        formUserGenderFemale: "Female",
        formUserBirth: "Birth date",
        formValidatorErrorMsgEmptyField: "This is required field.",
        pagination: {
          gallery: {
            description_one: "{{items}} picture loaded of {{count}}",
            description_other: "{{items}} pictures loaded of {{count}}",
          },
        },
        filter: {
          event: {
            name: "Event name",
          },
          increasing: "Increasing",
          decreasing: "Decreasing",
        },
        confirmations: {
          dialogue: {
            title: "Are you sure to remove this <strong>{{subject}}</strong> ?",
            title_other:
              "Are you sure to remove these <strong>{{subject}}</strong> ?",
            cancelUpload: "Are you sure to cancel upload ?",
            yes: "Yes",
            no: "No",
          },
        },
        picture: "Picture",
        form: {
          upload: {
            title: "Upload on event {{event_name}}",
            btn: "Upload one more pictures",
            message: {
              dragfiles:
                "Drag and drop some files here, or click to select files",
              inprogress:
                "Upload in progress...Please wait until the download is complete to perform another",
            },
          },
          newsletter: {
            field: {
              title: "NewsLetter subscription",
              placeholder: "your-email@example.com",
            },
          },
          forgotPassword: {
            send: "Send",
            backToLogin: "Back to login page",
          },
        },
        newsletter: {
          unsubscribe: {
            message: {
              notfound: "<p>Sorry your link is wrong<p/>",
              alreadydisabled:
                "<p>You have already unsubscribed to our newsletter.</p>",
              success:
                "<p>We are really sad to hear that you are already leaving us, don't worry if you change your mind, our newsletter subscription is still open for you.</p><p> Thank you for your presence with us during this time.</p>",
            },
          },
        },
        errors: {
          page404: {
            title: "Page not found",
            desc: `<p className="evzt-text-center">Sorry, the page who you visited does not exist</p>
                                    <p className="evzt-text-center">You can return to the home page by clicking on the link below:</p>`,
            btn: {
              backhomepage: `Back to the home page`,
            },
          },
          login: {
            invalid: "Invalid username and password combination",
          },
        },
        menu: {
          mobiles: {
            eventsImInvited: "Guest Events",
          },
        },
        messages: {
          errors: {
            unknowErrorNewsLetter: "Error while submitting",
            emailAlreadyOnNewSletter:
              "This mail address is already on newsLetter",
            emailIncorrect: "This mail address is incorrect",
          },
          success: {
            subscriptionNewsLetterDone:
              "Thank you for your subscription to our newsLetter",
            requestedRenewPassWord: "An email is sent to your address",
          },
        },
        socialSigninText: "Sign in via",
      },
    },
    fr: {
      translation: {
        alreadyAnAccount: "Vous avez déjà un compte",
        dontHaveAccountText: "Vous n'avez pas encore de compte",
        signUp: "S'inscrire",
        signIn: "Se connecter",
        logOut: "Déconnexion",
        home: "Accueil",
        ourService: "Nos Services",
        ServiceItems: {
          Wedding: "Mariage",
          Birthday: "Anniversaire",
          RestaurationDecoration: "Réstauration & Décoration",
          Findetude: "Sortie de promotion",
          ConferenceEvent: "Événement de conférence",
          CharityEvent: "Événement de Charité",
          CorporateEvent: "Événement corporatif",
          EventPhotography: "Événement de photographie",
          Exposition: "Éxposition",
          FamilyHoliday: "Réunion de famille",
        },
        AboutUs: "À propos de nous",
        MorePosts: "PLUS D'ARTICLES",
        SendRequest: "ENVOYER UNE DEMANDE",
        UsersReviews: "Avis des utilisateurs",
        ArticlesfromtheBlog: "Articles du blog",
        ContactUs: {
          Title: "Contactez-nous",
          Adress: "Emplacement",
          Phone: "Téléphone",
          ContactForum: "FORMULAIRE DE CONTACT",
          ContactUS: "Contactez-nous",
          YourMessage: "Envoyez-nous un message",
          CommentOrMessage: "Commentaire/Message",
          Send: "ENVOYER",
        },
        BackToMyspace: "Retour à Myspace",
        OurMostRecentEvent: "Nos événements les plus récents",
        firstName: "Prénom",
        lastName: "Nom de famille",
        password: "Mot de passe",
        newPassword: "Nouveau mot de passe",
        passwordConfirm: "Confirmation de mot de passe",
        email: "Adresse e-mail",
        createAccount: "Créer un nouveau compte",
        username: "Nom d'utilisateur",
        rememberMe: "Se souvenir de moi",
        forgotPassword: "Mot de passe oublié",
        requiredField: "Ce champ est requis",
        ifDontHaveAccount: "Si vous n'avez pas encore de compte",
        itSQuickEasy: "C'est simple et rapide",
        btnUpdateProfil: "Confirmer et sauvegarder",
        language: "Language",
        formMsgSuccessUpdProfile: "Profil modifié avec succès",
        myEvents: "Mes évènements",
        contacts_one: "Contact",
        contacts_other: "Contacts",
        noEvent: "Vous n'avez aucun évènement inscrit pour le moment",
        noEventInvited: "Vous n'êtes pas encore invité sur aucun évenement",
        eventsImInvited: "Evènements ou j'y étais",
        relatedImages: "Photos récents",
        privacy: "Politique de confidentialité",
        termsOfService: "Conditions de service",
        langChoice: "Choix de langue",
        about: "Apropos",
        aboutEvzt: "Comprendre Event Zara Tof : Notre vision et notre mission",
        whatIsEvzt:
          "Event-Zaratof is an online platform for people that organize often events which need a space and above all sharing the pictures by face recognition technology.",
        characteristics:
          "Event-ZaraTof helps you plan your event , such as managing guests for each event ,  also in event you can personalize your Event Title , date time ,invitation content email and picture link sharing mail to send with your guests.\n" +
          "\n" +
          "<p>Thanks to facial recognition, the person attending the event automatically received the link of the photo on which they were.</p> \n" +
          "<p><strong>So how do you make this happen ?</strong></p>\n" +
          "\n" +
          "<p>For this, a guest has received an invitation by email or sms must go to Event-ZaraTof and download their correct profile avatar.</p>\n",
        picturesFor: "Photos de l'evenement",
        events_one: "Évènement",
        events_other: "Évènements",
        loadMore: "Afficher de plus",
        loadLess: "Afficher moins",
        manageEvents: "Gérer les évènements",
        newEvent: "Créer un nouveau évènement",
        aboutYou: "Apropos de vous",
        security: "Sécurité",
        formEventName: "Nom de l'évènement",
        formEventNameDesc: "Entrer le nom de votre évènement ici",
        formEventDate: "Date de l'évènement",
        formEventConfigurationInvt: "Configuration de l'invitation",
        formEventEmail: "E-mail",
        formEventEmailDesc: "E-mail de l'emetteur",
        formEventSubject: "Objet de mail",
        formEventSubjectDesc: "Put the Subject of mail",
        formEventMailInvtContent: "Mail invitation content",
        formEventSMSInvtContent: "SMS invitation content",
        formEventConfigurationShare: "Configuration de partage des photos",
        formEventShareEmail: "E-mail",
        formEventEmailShareDesc: "E-mail de l'emetteur",
        formEventShareSubject: "Objet de mail",
        formEventShareSubjectDesc: "ex: Anniversaire de John Doe Fev 2000 ",
        formEventShareMailContent: "Corps de maail de partage",
        formEventShareSMSContent: "Contenut SMS de partage",
        formEventShareSMSContentDesc: "Contenu SMS de partage",
        formBtnCreate: "Créer",
        formUserFirstname: "Prénom(s)",
        formUserLastname: "Nom",
        formUserUsername: "Nom d'utilisateur",
        formUserEmail: "E-mail",
        formUserGender: "Sexe",
        formUserGenderMale: "Masculin",
        formUserGenderFemale: "Feminin",
        formUserBirth: "Date de naissance",
        formValidatorErrorMsgEmptyField: "Veuillez remplir ce champ svp.",
        form: {
          upload: {
            title: "Importer dans l'évènement {{event_name}}",
            btn: "Importer des photos",
            message: {
              dragfiles:
                "Déposer vos images ici, ou cliquer et chosir vos photos",
              inprogress:
                "Upload encours...Veuillez attendre que tous les photos soient télécharger completement pour faire une autre action.",
            },
          },
          newsletter: {
            field: {
              title: "Inscription à la newsLetter",
              placeholder: "votre-email@example.com",
            },
          },
          forgotPassword: {
            send: "Envoyer",
            backToLogin: "Revenir dans la page de login",
          },
        },
        pagination: {
          gallery: {
            description_one: "{{items}} photo chargé sur {{count}}",
            description_other: "{{items}} photos chargé sur {{count}}",
          },
        },
        filter: {
          event: {
            name: "Nom de l'évènement",
          },
          increasing: "Croissant",
          decreasing: "Décroissant",
          date: {
            most: "Plus récent",
            older: "Ancien",
          },
        },
        confirmations: {
          dialogue: {
            title: "Êtes-vous sur de supprimer <strong>{{subject}}</strong> ?",
            title_other:
              "Êtes-vous sur de supprimer les <strong>{{subject}}</strong> ?",
            cancelUpload: "Êtes-vous sur d'annuler l'import ?",
            yes: "Oui",
            no: "Non",
          },
        },
        picture: "Photo",
        newsletter: {
          unsubscribe: {
            message: {
              notfound: "<p>Désolé, votre lien n'est plus valable</p>",
              alreadydisabled:
                "<p>Vous êtes déjà désabonné de notre newsletter.</p>",
              success:
                "<p>Nous sommes vraiment tristes d'apprendre que vous nous quittez déjà, ne vous inquiétez pas si vous changez d'avis, notre abonnement à la newsletter est toujours ouvert pour vous.</p><p>Merci de votre présence parmi nous pendant cette période.</p>",
            },
          },
        },
        errors: {
          page404: {
            title: `Page non trouvée`,
            desc: `<p>
                                        Désolé, la page que vous recherchez semble introuvable. Il se peut qu'elle ait été déplacée, renommée ou supprimée.
                                    </p>
                                    <p>Vous pouvez retourner à la page d'accueil en cliquant sur le lien ci-dessous :</p>`,
            btn: {
              backhomepage: `Retour à la page d'accueil`,
            },
          },
          login: {
            invalid: "Nom utilisateur ou mot de passe incorrect",
          },
        },
        menu: {
          mobiles: {
            eventsImInvited: "Événements invités",
          },
        },
        messages: {
          errors: {
            unknowErrorNewsLetter: "Erreur lors de la soumission",
            emailAlreadyOnNewSletter:
              "L'adresse e-mail que vous avez ajouté est déjà enregistré dans notre newsLettter",
            emailIncorrect: "Adresse e-mail incorrecte",
          },
          success: {
            subscriptionNewsLetterDone:
              "Merci pour votre inscription à notre newsLetter",
            requestedRenewPassWord: "An email is sent to your address",
          },
        },
        socialSigninText: "Se connecter via",
      },
    },
  },
});

export default i18n;
